const HEADING_REGEX = /(<h2>|<h3>)((?!(<\/h2>|<\/h3>)).)*(<\/h2>|<\/h3>)/gi
const IMAGE_REGEX = /<img /gi
const START_HEADING_POSITION = 4
const END_HEADING_POSITION = -5
const DOFOLLOW_LINKS = ['https://ling-app.com/learn-cantonese/']

const clearString = (string: string) =>
  string
    .slice(START_HEADING_POSITION, END_HEADING_POSITION)
    .replaceAll(/<((?!>).)*>|&nbsp;/gi, '')

const setLinksRelAttribute = (
  html: string,
  doFollowLinksFromStrapi?: string[]
): string => {
  const anchorRegex = /<a([^>]*)>/g
  const hrefRegex = /href\s*=\s*["']([^"']*)["']/
  const domainRegex = /promova\.com/
  const slashStartRegex = /^\/.*/

  return html.replace(anchorRegex, (match, attributes: string) => {
    let attribs = attributes

    if (!attribs.match(hrefRegex)) {
      return match
    }

    const hrefValue = hrefRegex.exec(attribs)?.[1]

    if (
      hrefValue &&
      !hrefValue.match(domainRegex) &&
      !hrefValue.match(slashStartRegex)
    ) {
      if (
        DOFOLLOW_LINKS.includes(hrefValue) ||
        doFollowLinksFromStrapi?.includes(hrefValue)
      ) {
        attribs += ' rel="dofollow"'
      } else if (attribs.match(/rel\s*=\s*["'][^"']*["']/)) {
        attribs = attribs.replace(
          /rel\s*=\s*(["'][^"']*)["']/,
          'rel=$1 nofollow"'
        )
      } else {
        attribs += ' rel="nofollow"'
      }
    }

    return `<a${attribs}>`
  })
}

export const createSlug = (string: string) =>
  string.toLowerCase().replaceAll(' ', '_').replaceAll(/\W/gi, '')

export const createContentsArray = (content: string) => {
  const headingMatches = Array.from(content?.matchAll(HEADING_REGEX))
  return headingMatches.map((item) => ({
    value: clearString(item[0]),
    type: item[1],
    index: item.index,
  }))
}

// function to add anchors to headings and add lazy loading to images
export const headingAndImageReplacer = (
  content: string,
  doFollowLinksFromStrapi?: string[]
): string =>
  setLinksRelAttribute(content, doFollowLinksFromStrapi)
    .replaceAll(
      HEADING_REGEX,
      (match): string =>
        `<a id="${createSlug(clearString(match))}"></a>${match}`
    )
    .replaceAll(IMAGE_REGEX, (match): string => `${match}  loading="lazy" `)
